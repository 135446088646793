"use client";
import { useEffect, useRef, useState } from "react";

function useInViewPort<T extends HTMLElement>(options: {
  offset?: number;
  once?: boolean;
}) {
  const { once, offset } = { once: false, offset: 0, ...options };

  const elementRef = useRef<T>(null);
  const [isInViewport, setIsInViewport] = useState(false);

  const handleScroll = () => {
    if (elementRef.current) {
      const { top, bottom } = elementRef.current.getBoundingClientRect();
      const halfwayPoint = top + (bottom - top) / 2;
      const checkIsInViewport =
        halfwayPoint >= offset && halfwayPoint <= window.innerHeight - offset;
      if (checkIsInViewport) {
        // Element is in viewport
        // Add your logic here
        setIsInViewport(true);
        if (once) {
          window.removeEventListener("scroll", handleScroll);
        }
      } else {
        // Element is not in viewport
        // Add your logic here
        setIsInViewport(false);
      }
    }
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return { ref: elementRef, isInViewport };
}

export default useInViewPort;
