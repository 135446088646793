import { FC, ReactNode } from "react";

interface AlertProps {
  children: ReactNode;
  variant: "success" | "error";
}

const Alert: FC<AlertProps> = ({ variant, children }) => {
  return (
    <div
      className={`p-4  border rounded-md ${variant == "success" ? "text-green-400 bg-green-100 border-green-400" : "text-red-400 bg-red-100 border-red-400"}`}
    >
      <p>{children}</p>
    </div>
  );
};

export default Alert;
