import { FC, ReactNode } from "react";
interface SectionHeaderProps {
  color_line?: String;
  children: ReactNode;
}

const SectionHeader: FC<SectionHeaderProps> = ({ children, color_line }) => {
  return (
    <span className="flex justify-center items-center gap-4 text-center">
      <div
        className={`h-0 flex-1 border-2 border-gbci-accent border-${color_line}`}
      ></div>
      <h2 className="min-w-min sm:min-w-fit flex2D1 sm:flex-initial break-words text-4xl font-bold text-gbci-primary">
        {children}
      </h2>
      <div
        className={`h-0 flex-1 border-2 border-gbci-accent border-${color_line}`}
      ></div>
    </span>
  );
};

export default SectionHeader;
