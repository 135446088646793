"use client";
import { FC } from "react";
import Image from "next/image";
import infographic from "@/../../public/images/illustrations/gbci-infographic.jpg";
import useInViewPort from "@/hooks/useInViewport";

const Infographic: FC = () => {
  const { ref, isInViewport } = useInViewPort<HTMLDivElement>({
    once: true,
    offset: -1000,
  });

  return (
    <div
      className={`px-page-xl py-8 ${isInViewport ? "animate-fadein" : "opacity-0"}`}
      ref={ref}
    >
      <Image className="w-full" src={infographic} alt="" />
    </div>
  );
};

export default Infographic;
