"use client";
import SectionHeader from "@/components/common/section-header";
import { FC, ReactNode } from "react";
import { DailyManagementSVG } from "./svgs/impetus";
import { CoachingSVG, MentoringSVG, TrainingSVG } from "./svgs/services";
import useInViewPort from "@/hooks/useInViewport";

const Service: FC<{
  header: string;
  services: string[];
  extendedStyle: string;
  icon: ReactNode;
}> = ({ header, services, extendedStyle, icon }) => {
  const { ref, isInViewport } = useInViewPort({ once: true });

  return (
    <article
      className={`relative overflow-hidden p-8 h-full w-full rounded-md flex flex-col gap-4 min-w-60 drop-shadow-lg ${isInViewport ? "animate-fadein" : "opacity-0"} ${extendedStyle}`}
      ref={ref}
    >
      <h3 className="text-4xl font-bold">{header}</h3>
      <ul className="">
        {services.map((service, index) => (
          <li key={index}>
            <p className="text-xl leading-relaxed">{service}</p>{" "}
          </li>
        ))}
      </ul>

      <div className="w-60 h-60 absolute mt-4 top-1/2 -translate-y-1/2 right-0 opacity-15 -rotate-12">
        {icon}
      </div>
    </article>
  );
};

export default function WhatWeDo() {
  return (
    <section className="px-page-xl py-16">
      <SectionHeader>WHAT WE DO</SectionHeader>

      <div className="pt-16 items-start gap-16 flex flex-col md:grid md:grid-cols-2 md:grid-rows-[repeat(3, 100px)]">
        <Service
          header={"Evaluation"}
          services={[
            "Cultural",
            "Organizational",
            "Operational",
            "Technological",
            "Industrial",
          ]}
          extendedStyle="bg-gradient-to-br from-white to-slate-100 col-start-1 col-end-1 row-span-2"
          icon={<DailyManagementSVG />}
        />

        <Service
          header={"Coaching"}
          services={[
            "Strategic Planning",
            "Execution of Projects",
            "Project Management",
          ]}
          extendedStyle="bg-gradient-to-br from-slate-900 to-gbci-primary text-white col-start-1 col-end-1 row-span-1"
          icon={<CoachingSVG className="fill-white scale-75" />}
        />

        <Service
          header={"Training"}
          services={["Lean", "Six Sigma", "Innovation", "Agile", "Leadership"]}
          extendedStyle="bg-gradient-to-br from-white to-slate-100 col-start-2 col-end-2 row-start-2 row-span-2"
          icon={<TrainingSVG />}
        />

        <Service
          header={"Mentoring"}
          services={["Change Management", "Communication", "Leadership"]}
          extendedStyle="bg-gradient-to-br from-gbci-primary to-gbci-accent text-white col-start-2 col-end-2 row-start-1 row-span-1"
          icon={<MentoringSVG className="fill-white scale-75" />}
        />
      </div>
    </section>
  );
}
