"use client";
import OpexDiagram from "@/../public/images/illustrations/opex-diagram.png";
import Image from "next/image";
import useInViewPort from "@/hooks/useInViewport";

export default function AboutOpex() {
  const { ref, isInViewport } = useInViewPort({ once: true });

  return (
    <section className="bg-gradient-to-br from-slate-900 to-gbci-primary">
      <article
        className={`mx-page-xl flex flex-col md:flex-row gap-16 justify-center items-center py-16 ${isInViewport ? "animate-fadein" : "opacity-0"}`}
        ref={ref}
      >
        <Image
          className="flex-1 max-w-full md:max-w-[calc(100%/3*2)]"
          src={OpexDiagram}
          alt="Opex Diagram"
        />

        <div className="flex-1 flex flex-col gap-4 text-white drop-shadow-md">
          <h2 className="text-4xl font-bold">What is OPEX?</h2>
          <h3 className="text-2xl font-bold">Operational Excellence</h3>

          <p className="text-xl">
            The continuous effort towards medium- and long-term objectives to
            transform and innovate.
          </p>
          <p className="text-xl">
            It is a systematic approach to translating customer needs into a
            product or service using a new way of thinking by using new
            materials, designs, or even new technology.
          </p>
        </div>
      </article>
    </section>
  );
}
