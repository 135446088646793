"use client";
import Button from "@/components/common/button";
import SectionHeader from "@/components/common/section-header";
import { FC, ReactNode } from "react";
import StaffImg from "@/../public/images/figures/staff.png";

import BusinesssTransformationImg from "@/../public/images/figures/impetus/business-transformation.png";
import ContinousImprovementImg from "@/../public/images/figures/impetus/continous-improvement.png";
import DailyManagementImg from "@/../public/images/figures/impetus/daily-management.png";
import InnovationImg from "@/../public/images/figures/impetus/innovation.png";
import ProcessAutomationImg from "@/../public/images/figures/impetus/process-automation.png";

import Image from "next/image";
import useInViewPort from "@/hooks/useInViewport";
import {
  AutomationSVG,
  BusinesssTransformation,
  ContinousImprovementSVG,
  DailyManagementSVG,
  InnovationSVG,
} from "./svgs/impetus";
import Link from "next/link";

const Article: FC<{
  children: ReactNode;
  header: string;
  subheader: string;
  image: ReactNode;
  CTA?: ReactNode;
  order: "left" | "right";
  iconSVG: ReactNode;
}> = ({ children, header, subheader, image, CTA, order, iconSVG }) => {
  const { ref, isInViewport } = useInViewPort<HTMLDivElement>({ once: true });

  return (
    <article
      className={`relative flex flex-col gap-8 ${order == "left" ? "lg:flex-row" : "lg:flex-row-reverse"} ${isInViewport ? "animate-fadein" : "opacity-0"}`}
      ref={ref}
    >
      <div className={`flex-1 flex flex-col gap-4 items-start`}>
        <h3 className="text-2xl font-bold uppercase text-gbci-primary flex items-center gap-3">
          {subheader}

          <div className="w-10 h-10 fill-gbci-primary">{iconSVG}</div>
        </h3>
        <h4 className="text-xl font-bold">{header}</h4>
        <p className="mt-4 mb-6 text-xl leading-relaxed">{children}</p>
        {CTA}
      </div>

      <div className="flex-1">
        <div
          className={`${order == "left" ? "lg:ml-8" : "lg:mr-8"} max-h-[500px]`}
        >
          {image}
        </div>
      </div>
    </article>
  );
};

export default function WhatMakesUs() {
  return (
    <section className="px-page-xl py-16">
      <SectionHeader>WHAT MAKES US</SectionHeader>

      <div className="pt-16 flex flex-col gap-16">
        <Article
          header="Rethinking Technology "
          subheader="Business Transformation"
          image={
            <Image
              className="h-[400px] object-cover rounded-md"
              src={BusinesssTransformationImg}
              alt=""
            />
          }
          order="left"
          // CTA={<Link href='/tool/'><Button size="large">Know my Impetus</Button></Link>}
          iconSVG={<BusinesssTransformation />}
        >
          It is a radical redesign of a value stream to achieve breakthrough
          goals, which includes a re-evaluation of the use of current
          technology, organizational structure realignment, and enhancement of
          current process standards executed over a short time.
        </Article>
        <Article
          header="Changing the Workplace"
          subheader="Daily Management"
          image={
            <Image
              className="h-[400px] object-cover rounded-md"
              src={DailyManagementImg}
              alt=""
            />
          }
          order="right"
          iconSVG={<DailyManagementSVG />}
        >
          It is a metrics-based operations management system that defines a
          cadence for daily meetings and communication that improves
          accountability and facilitates problem-solving escalation of events to
          the proper levels.
        </Article>
        <Article
          header="Working better. Every day"
          subheader="Continuous Improvement"
          image={
            <Image
              className="h-[400px] object-cover rounded-md"
              src={ContinousImprovementImg}
              alt=""
            />
          }
          order="left"
          iconSVG={<ContinousImprovementSVG />}
        >
          It is a set of methodologies (Kaizen, DMAIC, Workout, PDCA, RCFA).
          that uses data or subject matter experts’ opinions to improve the
          product or process performance incrementally. It is organized into
          many small events that are planned for an extended period of time.
        </Article>
        <Article
          header="Making the impossible possible"
          subheader="Innovation"
          image={
            <Image
              className="h-[400px] object-cover rounded-md"
              src={InnovationImg}
              alt=""
            />
          }
          order="right"
          iconSVG={<InnovationSVG />}
        >
          We believe that it is possible to intentionally and systemically look
          for innovation. We help our clients implement different methodologies
          to address their efforts to identify or develop outstanding and
          valuable products and services that disrupt their industry or expand
          their scope to other markets.
        </Article>
        <Article
          header="Doing it right, doing it fast"
          subheader="Process Automation"
          image={
            <Image
              className="h-[400px] object-cover rounded-md"
              src={ProcessAutomationImg}
              alt=""
            />
          }
          order="left"
          CTA={
            <Link href="/#contact">
              <Button size="large">Get in Contact</Button>
            </Link>
          }
          iconSVG={<AutomationSVG />}
        >
          Once the transformation effort is consolidated in our client’s
          culture, we are capable of smoothly transitioning the transformed
          processes to automation using diverse technologies such as RPA, AI,
          software development, etc. to provide intuitive and valuable user
          experiences to ultimate customers.
        </Article>
      </div>
    </section>
  );
}
